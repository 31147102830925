<script>
export default {
  name: "AboutUsBonus",
  props: {
    record: Object
  }
}
</script>

<template>
  <div class="bonus-container">
  <h2 class="subheader-color">{{record.header}}</h2>
  <p class="desc">{{record.desc}}</p>
  <div v-for="bonus in record.list" class="bonus-item  mb-3">
    <span class="pi pi-check bonus-icon"></span>
    <span class="desc">{{bonus}}</span>
  </div>
  </div>
</template>

<style scoped>
.bonus-container {
  border: 2px solid #8B5CF6;
  border-radius: 15px;
  justify-content: center;
  height: 500px;
  max-width: 400px;
  overflow: hidden;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  margin-top: 2rem;
  padding: 2rem;
}
.subheader-color {
  color: #8B5CF6;
}
.desc {
  text-align: justify;
  font-size: 1rem;
  color: #4b5563;
  flex: 1;
}
.bonus-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  background-color: #8B5CF6;
  color: white;
  border-radius: 50%;
  font-size: 12px;
  font-weight: bold;
  flex-shrink: 0;
}
.bonus-item {
  display: flex;
  align-items: center;
  gap: 8px;
}

</style>