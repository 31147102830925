<script>
import {FilterMatchMode} from "primevue/api";

export default {
  name: "LoginsQuery",
  props: {
    records: Array
  },
  data() {
    return {
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        username: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        email: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        device: { value: null, matchMode: FilterMatchMode.EQUALS },
      }
    }
  },
  methods: {
    getSeverity(value) {
      return value === 0 ? 'danger' : 'success';
    }
  }
}
</script>

<template>
  <DataTable :value="records" v-model:filters="filters" tableStyle="width: 100%" resizableColumns columnResizeMode="fit" size="small" stripedRows
             paginator :rowsPerPageOptions="[10,25,50]" :rows="25"
             paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
             currentPageReportTemplate="{first} - {last} z {totalRecords}">
    <template #header>
      <div class="flex justify-content-end">
        <IconField iconPosition="left">
          <InputIcon>
            <i class="pi pi-search" />
          </InputIcon>
          <InputText v-model="filters['global'].value" placeholder="Szukaj" />
        </IconField>
      </div>
    </template>
    <template #empty> No customers found. </template>
    <template #loading> Loading customers data. Please wait. </template>
    <Column field="id" header="Id"></Column>
    <Column field="username" header="Username"></Column>
    <Column field="email" header="Email"></Column>
    <Column field="createdDate" header="Created Date"></Column>
    <Column field="expiredDate" header="Expired Date"></Column>
    <Column header="Is Used">
      <template #body="slotProps">
        <Tag
            :value="slotProps.data.isUsed || '0'"
            :severity="getSeverity(slotProps.data.isUsed)" />
      </template>
    </Column>
    <Column field="device" header="Device"></Column>

  </DataTable>
</template>

<style scoped>

</style>