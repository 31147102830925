<script>
import { FilterMatchMode } from 'primevue/api';
export default {
  name: "UsersQuery",
  props: {
    records: Array
  },
  data() {
    return {
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        username: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        email: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        type: { value: null, matchMode: FilterMatchMode.EQUALS }
      }
    }
  },
  methods: {

    formatValue(value) {
      return value === 1 ? 'true' : 'false';
    },
    getSeverity(value) {
      return value === 1 ? 'success' : 'danger';
    }
  }
}
</script>

<template>
  <div class="mb-4">
    <strong>Wszystkich użytkowników: {{this.records.length}}</strong>
  </div>
  <DataTable :value="records" v-model:filters="filters" tableStyle="width: 100%" resizableColumns columnResizeMode="fit" size="small" stripedRows
             paginator :rowsPerPageOptions="[10,25,50]" :rows="25"
             paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
             currentPageReportTemplate="{first} - {last} z {totalRecords}">
    <template #header>
      <div class="flex justify-content-end">
        <IconField iconPosition="left">
          <InputIcon>
            <i class="pi pi-search" />
          </InputIcon>
          <InputText v-model="filters['global'].value" placeholder="Szukaj" />
        </IconField>
      </div>
    </template>
    <template #empty> No customers found. </template>
    <template #loading> Loading customers data. Please wait. </template>
    <Column field="username" header="Username"></Column>
    <Column field="email" header="Email"></Column>
    <Column field="createdDate" header="Created Date"></Column>
    <Column field="type" header="Type"></Column>
    <Column field="customerId" header="Customer Id"></Column>
    <Column header="Is Premium user">
      <template #body="slotProps">
        <Tag
            :value="formatValue(slotProps.data.isPremiumUser)"
            :severity="getSeverity(slotProps.data.isPremiumUser)" />
      </template>
    </Column>
    <Column header="Email Verified">
      <template #body="slotProps">
        <Tag
            :value="formatValue(slotProps.data.isEmailVerified)"
            :severity="getSeverity(slotProps.data.isEmailVerified)" />
      </template>
    </Column>
    <Column field="subExpirationDate" header="Sub Exp Date"></Column>
    <Column header="FP Activated">
      <template #body="slotProps">
        <Tag
            :value="formatValue(slotProps.data.freePlanActivated)"
            :severity="getSeverity(slotProps.data.freePlanActivated)" />
      </template>
    </Column>
    <Column header="FP Expired">
      <template #body="slotProps">
        <Tag
            :value="formatValue(slotProps.data.freePlanExpired)"
            :severity="getSeverity(slotProps.data.freePlanExpired)" />
      </template>
    </Column>
  </DataTable>
</template>

<style scoped>

</style>