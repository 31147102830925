<script>
import axios from "axios";
import {computed, reactive} from "vue";
import {email, helpers, minLength, required} from "@vuelidate/validators";
import useValidate from "@vuelidate/core";
import {HalfCircleSpinner} from "epic-spinners";
import {GoogleLogin} from "vue3-google-login";
import { isMobile,isBrowser, deviceDetect } from 'mobile-device-detect';
export default {
  name: 'LoginModal',
  components: {GoogleLogin, HalfCircleSpinner},
  emits: ['login-completed', 'show-modal', 'show-toast', 'show-signup'],
  created() {
    this.checkUrl();
  },
  setup() {
    const state = reactive({
      email: '',
      password: ''
    })
    const rules = computed(() => {
      return {
        email: {
          required: helpers.withMessage('Pole Wymagane', required),
          email: helpers.withMessage('Błędny format', email)
        },
        password: {
          required: helpers.withMessage('Pole Wymagane', required),
          minLength: helpers.withMessage('Hasło musi zawierać co najmniej 6 znaków',minLength(6)),
        }
      }
    })
    const v$ = useValidate(rules, state);
    return {
      state,
      v$
    }
  },
  data() {
    return {
      showError: false,
      errorMessage: '',
      showLoginSection: true,
      email: '',
      showSignupModal: false,
      showSpinner: false,
      disableSingUpButton: false,
      callback: (response) => {
        // This callback will be triggered when the user selects or login to
        // his Google account from the popup
      }
    }
  },
  methods: {
    checkUrl() {
      const url = new URL(window.location.href);
      if(url.host.includes('localhost')
          || url.host.includes('dev')) {
        this.disableSingUpButton = true;
      }
    },
    async onSubmit() {
      this.v$.$validate();
      if (this.v$.$error) {
        this.$toast.add({severity: 'error', summary: 'Wypełnij poprawnie wszystkie pola', detail: '', life: 2000});
        return;
      }
      try {
        let userData = {
          email: this.state.email,
          password: this.state.password,
          device: isMobile ? 'mobile' : isBrowser ? 'browser' : ''
        }
        this.showSpinner = true;
        const response = await axios.post('/users/login', userData);
        if (response.status === 200) {
          this.showToast('success', 'Sukces','', 2000);
          this.resetForm();
          this.showSpinner = false;
          this.loginCompleted();
          this.$cookies.set('lz_token', response.data.token);
        } else {
          this.showSpinner = false;
          this.showToast('error', 'Błąd logowania',response.data.message, 2000);
          this.showError = true;
          this.errorMessage = response.data.message;
        }
      } catch (error) {
        this.showSpinner = false;
        console.error('Error: ', error);
        this.showToast('error', 'Błąd logowania',error, 2000);
        this.errorMessage = error;
      }
    },
    loginCompleted() {
      this.$emit('login-completed');
    },
    resetForm() {
      this.state.username = '';
      this.state.password = '';
    },
    handleForgotPassword() {
      this.showLoginSection = false;
    },
   async sendLink() {
      const response = await axios.post('/users/forgotPassword', {
        email: this.email
      });
      if(response.data.status === 200) {
        this.showToast('success', 'Sukces','Sprawdź swojego maila oraz spam', 2000);
        this.showModal();
      } else {
        this.showToast('error', 'Błąd', response.data.message, 2000);
      }
    },
    handleShowSignupModal() {
      this.$emit('show-signup');
    },
    showToast(severity, summary, detail, life) {
      this.$emit('show-toast', {
        severity: severity,
        summary: summary,
        detail: detail,
        life: life
      });
    },
    showModal() {
      this.$emit('show-modal', false);
    },
  }
}

</script>

<template>
  <div v-if="showSpinner" class="custom-spinner">
    <div class="spinner">
      <half-circle-spinner
          :animation-duration="2000"
          :size="80"
          color="#8B5CF6"
      />
    </div>
  </div>
  <div class="form-container">
    <div class="field">
      <form class="flex flex-column gap-2 mt-4">
        <div  v-if="showLoginSection">
        <div class="field mb-5">
          <span class="p-float-label">
            <InputText id="state.email" v-model="state.email" type="text" class="input" required/>
            <label class="label" for="state.email">Email</label>
          </span>
          <div class="error mt-1" v-if="v$.email.$error">
            {{ v$.email.$errors[0].$message}}
          </div>
        </div>
        <div class="field mb-5">
          <span class="p-float-label">
            <InputText id="state.password" v-model="state.password" type="password" class="input" required/>
            <label class="label" for="state.password">Hasło</label>
          </span>
          <div class="error mt-1" v-if="v$.password.$error">
            {{ v$.password.$errors[0].$message}}
          </div>
          <div class="error mt-1" v-if="showError">
            {{ errorMessage }}
          </div>
          <div class="text-right mt-2">
            <Button class="forgot-password" label="Nie pamiętasz hasła?" severity="help" text @click="handleForgotPassword"/>
          </div>
        </div>
        <div class="buttons-container">
          <div class="custom-grid">
            <div class="footer-button">
              <Button class="button button-border" label="Logowanie" icon="pi pi-sign-in" @click="onSubmit"></Button>
            </div>
            <template v-if="disableSingUpButton === false">
              <div class="footer-button text-center mt-5">
                Nie masz konta?
                  <Button class="sing-up-button button-border" text label="Zarejestruj się" @click="handleShowSignupModal"></Button>
              </div>
            </template>
          </div>
        </div>
        </div>
        <div v-else class="forgot-password-container">
          <h4 class="mb-4 forgot-password-header">Podaj email, którego podałeś przy rejestracji, wyślemy Ci link do zresetowania hasła</h4>
          <div class="field mb-5">
            <span class="p-float-label mt-1">
              <InputText id="email" v-model="email" type="text" class="input" required/>
              <label class="label" for="email">Email</label>
            </span>
          </div>
          <div class="buttons-container">
            <div class="custom-grid">
              <div class="footer-button">
                <Button class="button button-border" label="Wyślij link" icon="pi pi-send" @click="sendLink"></Button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<style scoped>
.input {
  width: 100%;
}
.right-column {
  text-align:right;
}
.custom-grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.footer-button {
  width: 100%;
}
.button {
  width: 100%;
}
.sing-up-button {
  padding: 0;
}
.field {
  margin-bottom: 1em;
}
.error {
  color: #f13030;
  font-size: .8em;
}
.forgot-password {
  padding: 0;
}
.or-label {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.custom-spinner {
  position: fixed;
  top: 0;
  left:0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(8px);
  z-index: 1000;
  text-align: center;
  justify-content: center;
  align-items: center;
}
.spinner {
  position: fixed;
  left: 40%;
  top: 40%;
}
@media screen and (max-width: 640px) {
  .input {
    font-size: 1rem;
  }
  .label {
    font-size: 1rem;
  }
  .forgot-password-header {
    font-size: 1rem;
  }
  .forgot-password {
    font-size: 1em;
  }
  .footer-button {
    width: 100%;
    font-size: 1rem;
  }
  .right-column {
    text-align:center;
  }
  .button {
    font-size: 1rem;
  }
  .or-label {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
}
</style>