<script>
import axios from 'axios';
import PlanCard from "@/components/PlanCard.vue";
export default {
  name: "Plans",
  components: {PlanCard},
  emits: ['plan-selected'],
  props: {
    hideButtons: Boolean,
    isNewUser: Boolean,
    userCreatedDate: Date
  },
  created(){
    this.getPlans();
  },
  data() {
    return {
      plans: [],
      discount: '',
      expiredDate: null,
      promoMessage: '',
      timerInterval: null,
      discountForNewUsersEnabled: false,
      discountEndDate: '',
      discountLabel: '',
      responsiveOptions: [
        {
          breakpoint: '1024px',
          numVisible: 3,
          numScroll: 1
        },
        {
          breakpoint: '600px',
          numVisible: 1,
          numScroll: 1
        }
      ],
      isMobile: window.innerWidth <= 600
    }
  },
  mounted() {
    window.addEventListener("resize", this.checkScreenSize);
    this.timerInterval = setInterval(this.updateTimer, 1000);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.checkScreenSize);
  },
  methods: {
    checkScreenSize() {
      this.isMobile = window.innerWidth <= 600;
    },
    async getPlans() {
      const response = await axios.get('/offers/get');
      this.plans = response.data.plans;
      if(response.data.discount.length < 0){
        return;
      }
      this.discount = response.data.discount[0];
      this.expiredDate = this.discount ?  new Date(this.discount?.expiredDate) : null;
      //this.discountForNewUsersEnabled = this.isNewUser;
      this.discountForNewUsersEnabled = false;
      const userCreatedDate = new Date(this.userCreatedDate);
      const userDiscountExpiryDate = new Date(userCreatedDate.getTime() + 48 * 60 * 60 * 1000);
      this.discountEndDate = this.expiredDate ? this.expiredDate : this.discountForNewUsersEnabled ? userDiscountExpiryDate : null;
      this.discountLabel = this.discount ? this.discount.label : this.discountForNewUsersEnabled ? 'Specjalna zniżka dla nowego użytkownika' : '';
      this.updateTimer();
    },
    updateTimer() {
      if (!this.discountEndDate) {
        this.promoMessage = '';
        return;
      }
      const now = new Date();
      const timeLeft = this.discountEndDate - now;
      if (timeLeft > 0) {
        const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
        const hours = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);
        this.promoMessage = `${this.discountLabel} kończy się za ${days}d ${hours}h ${minutes}m ${seconds}s`;
      } else {
        clearInterval(this.timerInterval);
        this.promoMessage  = '';
      }
    },
    handlePlanSelected(event){
      this.$emit('plan-selected', event);
    }
  }
}
</script>

<template>
  <div v-if="discountLabel" class="discount">
    <h2 class="header">{{promoMessage}}</h2>
  </div>
  <div>
    <div v-if="!isMobile" class="custom-grid">
      <div v-for="plan in plans" :key="plan.id" class="single-plan">
        <PlanCard
            :plan="plan"
            @plan-selected="handlePlanSelected"
            :hide-buttons="hideButtons"
            :discount-for-new-users-enabled="discountForNewUsersEnabled"
        />
      </div>
    </div>
    <Carousel  v-else
               :value="plans"
               :numVisible="1"
               :numScroll="1"
               :circular="true"
               :showNavigators="false"
               :showIndicators="true">
      <template #item="slotProps">
        <PlanCard
            :plan="slotProps.data"
            @plan-selected="handlePlanSelected"
            :hide-buttons="hideButtons"
            :discount-for-new-users-enabled="discountForNewUsersEnabled"
        />
      </template>
    </Carousel>
  </div>
</template>

<style scoped>
.discount{
  margin-bottom: 3rem;
  margin-top:0;
}
.custom-grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.single-plan {
  width:30%;
  margin-left: auto;
  margin-right: auto;
}
.header{
  color: #22c55e;
  margin-top: 0;
  margin-bottom: 0;
}
.p-carousel .p-carousel-items {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 640px) {
  .single-plan {
    width: 100%;
    margin-bottom: 2em;
  }
}
</style>