<script>
import LoginModal from "@/components/LoginModal.vue";
import SignupModal from "@/components/SignupModal.vue";
import PlanCard from "@/components/PlanCard.vue";
import AboutUsBonus from "@/components/AboutUsBonus.vue";
import Button from "primevue/button";

export default {
  name:'MainPage',
  emits: ['logout'],
  components: {Button, AboutUsBonus, PlanCard, SignupModal, LoginModal},
  data() {
    return {
      showLoginModal: false,
      showSignupModal: false,
      callback: (response) => {
        // This callback will be triggered when the user selects or login to
        // his Google account from the popup
      },
      records: [
        {
          id: 1,
          header: 'Dla uczniów',
          desc: 'Nasza aplikacja oferuje interaktywne zadania, szczegółowe wyjaśnienia krok po\n' +
              'kroku, które pomagają w utrwaleniu wiedzy i doskonaleniu umiejętności.',
          list: ['Powtórka do matury', 'Przygotowanie przed sprawdzianem z danego działu', 'Punkty za poprawnie wykonane zadania', 'Możliwość zdobywania osiagnieć za ukończone działy', 'Forma rywalizacji z innymi uczniami']

        },
        {
          id: 2,
          header: 'Dla nauczycieli',
          desc: 'Losujzadanie to świetne rozwiązanie dla nauczycieli matematyki uczących w szkołach i prowadzących zajęcia indywidualne.',
          list: ['Losowe zadania ze wszystkich dostępnych matur', 'Rozwiązania krok po kroku ułatwiające prace z uczniem','Listy zadań dla nauczycieli – twórz spersonalizowane listy zadań dla uczniów', 'Prace domowe', 'Arkusze maturalne']
        }
      ]
    }
  },
  mounted() {
    const token = this.getCookie('lz_token');
    if(!token) {
      this.$emit('logout');
    }
  },
  methods: {
    handleButtonClick(){
      const token = this.getCookie('lz_token');
      if(token) {
        this.$router.push('/losuj-zadanie');
      } else {
        this.handleShowLoginModal();
      }
    },
    handleShowSignupModal() {
      this.showLoginModal = false;
      this.showSignupModal = !this.showSignupModal;
    },
    handleSignupCompleted() {
      this.$forceUpdate();
      this.handleShowSignupModal();
    },
    handleShowLoginModal() {
      this.showLoginModal = !this.showLoginModal;
    },
    handleLoginCompleted() {
      this.$forceUpdate();
      this.handleShowLoginModal();
    },
    showToastMessage(event){
      this.$toast.add({severity: event.severity, summary: event.summary, detail: event.detail, group:'tc', life: event.life});
    },
    getCookie(name) {
      return this.$cookies.get(name);
    },
    openLink(url) {
      window.open(url, '_blank');
    }
  },

}
</script>

<template>
  <section class="hero text-center">
    <h1 class="header drop-shadow">Losuj Zadanie</h1>
    <h1 class="header drop-shadow">Zadania maturalne z matematyki</h1>
  </section>
  <div class="card card-panel">
    <div class="card-body-local">
      <section class="about curved-top">
        <h2 class="subheader">O nas</h2>
        <div class="grid align-items-center justify-content-center">
          <div class="md:col-6 sm:col-12">
            <p class="desc">Witaj na naszej stronie, stworzonej przez małżeństwo: Przemka, doświadczonego
              programistę, i Anię, świetną nauczycielkę matematyki. Nasza aplikacja oferuje kompleksowe materiały do nauki
              matematyki na poziomie maturalnym, oparte na analizie matur z poprzednich lat.</p>
            <p class="desc">Nasza baza zawiera aż <strong>1000</strong> zadań z poziomu podstawowego oraz rozszerzonego i stale się rozwija!</p>
            <p class="desc">Na naszej stronie możesz zbierać punkty za poprawnie ukończone zadania oraz osiągniecią za ukończone działy.</p>
            <div class="text-center">
              <Button class="mt-2 button button-border" label="Zaczynamy" @click="handleButtonClick"></Button>
            </div>
          </div>
          <div class="md:col-6 sm:col-12">
              <img src="@/assets/drake.jpg" class="img" alt="drake.jpg"/>
          </div>
        </div>
      </section>
      <section class="for-students-teachers grey">
        <h2 class="subheader">Dla kogo?</h2>
        <p class="desc">Serwis skierowany jest zarówno do uczniów szkół średnich, jak i nauczycieli, którzy szukają gotowych zadań z rozwiązaniami do wykorzystania na lekcjach.</p>
        <div class="grid align-items-center">
          <div v-for="record in records" :key="record.id" class="md:col-6 sm:col-12  flex justify-content-center  ">
            <AboutUsBonus :record="record" />
          </div>
        </div>
      </section>
      <section class="instagram curved-bottom">
        <h2 class="subheader">Nasz instagram</h2>
        <p class="desc">Dołącz do naszej społeczności już dziś i odkryj, jak łatwo osiągnąć sukces na egzaminie maturalnym z
          matematyki.</p>
        <p class="desc">Zaufaj <span class="strong">losujzadanie.pl</span> – Twojemu najlepszemu partnerowi w nauce matematyki! Śledź nas na Instagramie, gdzie znajdziesz śmieszne matematyczne memy oraz aktualne promocje na płatne pakiety.</p>
        <Button class=" button-insta button-border" label="Odwiedź nas na instagramie"  @click="openLink('https://www.instagram.com/losujzadanie.pl/')"></Button>
      </section>
    </div>
  </div>
  <Dialog v-model:visible="showSignupModal" modal header="Rejestracja" :style="{ width: '25rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
    <SignupModal @show-modal="handleShowSignupModal" @register-completed="handleSignupCompleted" @show-toast="showToastMessage"></SignupModal>
  </Dialog>
  <Dialog v-model:visible="showLoginModal" modal header="Logowanie" :style="{ width: '25rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
    <LoginModal @show-modal="handleShowLoginModal" @login-completed="handleLoginCompleted" @show-toast="showToastMessage" @show-signup="handleShowSignupModal"></LoginModal>
  </Dialog>
</template>

<style scoped>
.card-center {
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-top: 5rem;
  padding-bottom: 5rem;
}
.card-body-local {
  background-color: white;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border-radius: 1rem;
  width: 80rem;
  min-height:20rem;
}
.grey {
  background-color: #f5f7fa;
}
.curved-top {
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}
.curved-bottom {
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
}
.container{
  display: flex;
  align-items: center;
  justify-content: center;
}
.header {
  font-size: 4rem;
  color: #8B5CF6;
}
.drop-shadow {
  text-shadow: 0.000em 0.075em #fff, 0.029em 0.069em #fff, 0.053em 0.053em #fff, 0.069em 0.029em #fff, 0.075em 0.000em #fff, 0.069em -0.029em #fff, 0.053em -0.053em #fff, 0.029em -0.069em #fff, 0.000em -0.075em #fff, -0.029em -0.069em #fff, -0.053em -0.053em #fff, -0.069em -0.029em #fff, -0.075em -0.000em #fff, -0.069em 0.029em #fff, -0.053em 0.053em #fff, -0.029em 0.069em #fff;
}
.subheader {
  font-size: 2rem;
}
.desc {
  text-align: justify;
  font-size: 1rem;
  color: #4b5563;
}
.hero, .about, .for-students-teachers, .instagram {
  padding: 40px;
}
.button-insta {
  background-color: #ff4081;
  border:1px solid #ff4081;
  color: white;
}
.button-insta:hover {
  text-decoration: none;
  background-color: #e73370;
}
.button {
  width: 30em;
  font-size: 1.2rem;
}
.img {
  width: 100%;
  border: 4px solid #4b5563;
  border-radius: 50px;
}
.strong {
  font-weight: bold;
  color: #8B5CF6;
}
@media screen and (max-width: 640px) {
  .header {
    font-size: 2.5rem;
  }
  .subheader {
    font-size: 1.5rem;
  }
  .desc {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    font-size: 1rem;
  }
  .img {
    margin-top: 1rem;
  }
  .button {
    width:100%;
    font-size: 1em;
  }
}
</style>