<script>
import Subscriptions from "@/components/Subscriptions.vue";
import {tabs} from "@/assets/utils";
import axios from "axios";

export default {
  name: "UserPlan" ,
  components: {Subscriptions},
  emits: ['logout'],
  created() {
    this.getToken();
    this.showSpinner = false;
  },
  data() {
    return {
      token: '',
      showSpinner: false,
      tabs: tabs,
      selectedTab: ''
    }
  },
  methods: {
    async getToken() {
      this.token = this.getCookie('lz_token');
      if (this.token) {
        const response = await axios.get('/users/checkUser', {
          headers: {
            Authorization: this.token
          }
        });
        this.user = response.data.user;
        if (!this.user) {
          console.log('## in logout')
          this.$emit('logout');
        }
      } else {
        console.log('## in logout')
        this.$emit('logout');
      }
    },
    handleShowSpinner(value) {
      this.showSpinner = value;
    },
    getCookie(name) {
      return this.$cookies.get(name);
    }
  }
}
</script>

<template>
  <div class="card card-panel">
    <div class="card-body">
      <h2 class="default-color">Twój plan</h2>
      <div v-if="showSpinner" class="custom-spinner">
        <div class="spinner">
          <half-circle-spinner
              :animation-duration="2000"
              :size="80"
              color="#8B5CF6"
          />
        </div>
      </div>
      <Subscriptions ref="subs" :token="token" @show-spinner="handleShowSpinner"></Subscriptions>
    </div>
  </div>

</template>

<style scoped>

</style>