<script>
import axios from "axios";
import { reactive, computed } from 'vue';
import useValidate from '@vuelidate/core';
import {required, email, minLength, maxLength, sameAs, helpers } from '@vuelidate/validators';
import {HalfCircleSpinner} from "epic-spinners";
import leoProfanity from 'leo-profanity';
import { profanityList } from '@/assets/utils';
export default {
  name: "SignupModal",
  components: {HalfCircleSpinner},
  emits: ['register-completed', 'show-modal', 'show-toast'],
  created() {
    leoProfanity.add(profanityList);
  },
  setup() {
    const state = reactive({
      username: '',
      email: '',
      password: '',
      secondPassword: '',
      termsCheckbox: false
    })
    const rules = computed(() => {
      return {
        username: {
          required: helpers.withMessage('Pole Wymagane', required),
          notEmail: helpers.withMessage('Nazwa użytkownika nie może zawierać @', value => {
            return !value || !value.includes('@');
          })
        },
        password: {
          required: helpers.withMessage('Pole Wymagane', required),
          minLength: helpers.withMessage('Hasło musi zawierać co najmniej 6 znaków',minLength(6)),
          maxLength: helpers.withMessage('Hasło może zawierać maksymalnie 40 znaków',maxLength(40))
        },
        secondPassword: {
          required: helpers.withMessage('Pole Wymagane', required),
          sameAsPassword: helpers.withMessage('Hasła muszą być takie same', sameAs(state.password)),
        },
        email: {
          required: helpers.withMessage('Pole Wymagane', required),
          email: helpers.withMessage('Błędny format', email)
        },
        termsCheckbox: {
          required: helpers.withMessage('Należy zaakceptować regulamin', sameAs(true)),
        }

      }
    })
    const v$ = useValidate(rules, state);
    return {
      state,
      v$
    }
  },
  data() {
    return {
      showSpinner: false,
      userTypes: [
        {
          name: 'Uczeń',
          value: 'student'
        },
        {
          name: 'Nauczyciel',
          value: 'teacher'
        }
      ],
      userType:  {
        name: 'Uczeń',
        value: 'student'
      },
    }
  },
  methods: {
    async onSubmit() {
      this.v$.$validate();
      if (this.v$.$error) {
        this.showToast('error','Błąd rejestracji', 'Wypełnij poprawnie wszystkie pola', 2000);
        return;
      }
      if(leoProfanity.check(this.state.username)) {
        this.showToast('error','Bład rejestracji', 'Nazwa użytkownika zawiera niecenzuralne słowo', 2000);
        return;
      }
      const checkUsernameAndEmailResponse = await axios.post('/users/checkUsernameAndEmail', {
        username: this.state.username,
        email: this.state.email,
      });
      if(checkUsernameAndEmailResponse?.data.status === 400) {
        this.showToast('error', 'Błąd rejestracji',checkUsernameAndEmailResponse?.data.errorMessage, 2000);
        return;
      }
      try {
        let userData = {
          id: '',
          username: this.state.username,
          email: this.state.email,
          password: this.state.password,
          type: this.userType.value,
          createdDate: new Date()
        }
        this.showSpinner = true;
        const response = await axios.post('/users/signup', userData);
        if (response.data.success) {
          this.showToast('success', 'Sukces','Rejestracja powiodła się, sprawdź maila', 0);
          this.resetForm();
          this.showSpinner = false;
          this.registerCompleted();
        } else {
          this.showSpinner = false;
          let errorMessage = '';
          if(response.data.message.sqlMessage.includes('email')){
            errorMessage = 'Użytkownik z podanym mailem już jest zarejestrowany';
          } else if(response.data.message.sqlMessage.includes('username')) {
            errorMessage = 'Nazwa użytkownika zajęta, proszę wybrać inną';
          }
          this.showToast('error', 'Błąd rejestracji',errorMessage, 2000);
        }
      } catch (error) {
        this.showSpinner = false;
        console.error('Error: ', error);
        this.showToast('error', 'Błąd rejestracji',error, 2000);
      }
    },
    showModal() {
      this.$emit('show-modal', false);
    },
    registerCompleted() {
      this.$emit('register-completed');
    },
    handleTermsClick() {
      this.$emit('show-modal', false);
      this.$router.push('/regulamin');
    },
    showToast(severity, summary, detail, life) {
      if(life === 0) {
        this.$emit('show-toast', {
          severity: severity,
          summary: summary,
          detail: detail
        });
      } else {
        this.$emit('show-toast', {
          severity: severity,
          summary: summary,
          detail: detail,
          life: life
        });
      }

    },
    resetForm(){
      this.state.username = '';
      this.state.email = '';
      this.state.password = '';
      this.state.secondPassword = '';
      this.state.termsCheckbox = false;
    }
  }
}
</script>

<template>
  <div v-if="showSpinner" class="custom-spinner">
    <div class="spinner">
      <half-circle-spinner
          :animation-duration="2000"
          :size="80"
          color="#8B5CF6"
      />
    </div>
  </div>
  <div class="form-container">
    <div class="field">
      <form class="flex flex-column gap-2 mt-4">
        <div class="field">
          <span class="p-float-label">
            <InputText id="state.username" v-model="state.username" type="text" class="input" required/>
            <label class="label" for="state.username">Nazwa użytkownika</label>
          </span>
          <div class="error mt-1" v-if="v$.username.$error">
            {{ v$.username.$errors[0].$message}}
          </div>
        </div>
        <div class="field">
          <span class="p-float-label">
            <InputText id="state.email" v-model="state.email" type="email" class="input" pattern="+@example/.com" required/>
            <label class="label" for="state.email">Email</label>
          </span>
          <div class="error mt-1" v-if="v$.email.$error">
            {{ v$.email.$errors[0].$message}}
          </div>
        </div>
        <div class="field">
          <span class="p-float-label">
            <InputText id="state.password" v-model="state.password" type="password" class="input" required minLength="6"/>
            <label class="label" for="state.password">Hasło (min. 6 znaków)</label>
          </span>
          <div class="error mt-1" v-if="v$.password.$error">
            {{ v$.password.$errors[0].$message}}
          </div>
        </div>
        <div class="field">
          <span class="p-float-label">
            <InputText id="state.secondPassword" v-model="state.secondPassword" type="password" class="input" required minLength="6"/>
            <label class="label" for="state.secondPassword">Powtórz hasło (min. 6 znaków)</label>
          </span>
          <div class="error mt-1" v-if="v$.secondPassword.$error">
            {{ v$.secondPassword.$errors[0].$message}}
          </div>
        </div>
        <div class="field">
          <SelectButton v-model="userType" :options="userTypes" optionLabel="name" aria-labelledby="basic"/>
        </div>
        <div class="field">
          <span class="flex align-items-left">
            <Checkbox v-model="state.termsCheckbox" :binary="true" />
            <label class="ml-2 label" for="state.termsCheckbox">
              Akceptuje
              <Button class="terms-buttons" text label="regulamin" @click="handleTermsClick"></Button>
            </label>
          </span>
          <div class="error mt-1" v-if="v$.termsCheckbox.$error">
            {{ v$.termsCheckbox.$errors[0].$message}}
          </div>
        </div>
        <div class="buttons-container">
          <div class="custom-grid">
            <div class="footer-button">
              <Button class="button button-border"  label="Rejestracja" icon="pi pi-user" @click="onSubmit"></Button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<style scoped>
.input {
  width: 100%;
}
.field {
  margin-bottom: 1em;
}
.custom-grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.footer-button {
  width: 100%;
  margin-bottom: 1em;
}
.button {
  width: 100%;
}
.terms-buttons {
  padding:0;
}
.error {
  color: #f13030;
  font-size: .8em;
}
.custom-spinner {
  position: fixed;
  top: 0;
  left:0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(8px);
  z-index: 1000;
  text-align: center;
  justify-content: center;
  align-items: center;
}
.spinner {
  position: fixed;
  left: 40%;
  top: 40%;
}
@media screen and (max-width: 640px) {
  .input {
    font-size: 1rem;
  }
  .label {
    font-size: 1em;
  }
  .button {
    font-size: 1rem;
  }
}
</style>