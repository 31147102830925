<script>
export default {
  name: "PlanCardNew",
  emits: ['plan-selected'],
  props: {
    plan: Object,
    hideButtons: Boolean,
    discountForNewUsersEnabled: Boolean,
  },
  data() {
    return {
      interval: '',
      mode: '',
      isSinglePayment: false,
      pricePerMonth: 0,
      priceToShow: 0,
    }
  },
  created() {
    this.interval = 'zł';
    this.mode = this.plan.mode === 'subscription' ? 'Płatność odnawialna' : 'Płatność jednorazowa';
    this.isSinglePayment = this.plan.mode === 'payment';
    if(this.discountForNewUsersEnabled && this.plan.id === 4 && !this.plan.discountApplied) {
      this.plan.discount += 15;
      this.plan.discountApplied = true;
    }
    if (this.plan.discount > 0) {
      this.priceToShow = Math.round(Number(this.plan.price - this.plan.price * (this.plan.discount/100)));
      this.plan.price = Number(this.plan.price);
    } else {
      this.priceToShow = Number(this.plan.price);
    }
    this.pricePerMonth = Math.round(parseFloat(this.priceToShow) / (this.plan.value/3)*10);
    this.plan.amountToCharge = this.priceToShow;
  },
  methods: {
    handlePlanSelected(){
      this.$emit('plan-selected', this.plan);
    }
  }
}
</script>

<template>
  <div class="plan-container"
       :class="['plan-card', { 'most-popular': plan.isMostPopular === 1 }]">
    <div class="plan-content-header">
      <h3 v-if="plan.isMostPopular === 1" class="header">Najczęściej wybierany</h3>
    </div>
    <div class="plan-content">
<!--      <h3 class="plan-title">{{plan.title}}</h3>-->
      <template v-if="plan.discount === 0">
        <h1  class="price">{{priceToShow}} zł</h1>
      </template>
      <template v-else>
        <h1 class="price"> <span class="old-price">{{plan.price}}zł</span>{{priceToShow}}zł </h1>
      </template>
      <template v-if="plan.discount > 0">
        <p  class="discount-label">-{{plan.discount}}%</p>
        <p class="min-price">Najniższa cena z 30 dni przed obniżką <span class="min-price-value">{{plan.minPrice}} zł</span> </p>
      </template>
      <strong><p>Cena za miesiąc {{pricePerMonth}} zł</p></strong>
      <div class="bonus-container grey">
        <span class="pi pi-calendar bonus-icon"></span>
        <span class="price-text">Dostęp na <strong>{{plan.value}} dni</strong></span>
      </div>
      <div class="bonus-container">
        <span class="pi pi-unlock bonus-icon"></span>
        <span class="price-text">Dostęp do <strong>wszystkich</strong> zadań, również w formie arkuszy maturalnych</span>
      </div>
      <div class="bonus-container grey">
        <span class="pi pi-gift bonus-icon"></span>
        <span class="price-text"><strong>Nielimitowana</strong> liczba losowań</span>
      </div>

      <div class="bonus-container">
        <span class="pi pi-check bonus-icon"></span>
        <span class="price-text"><strong>Szybsze wsparcie</strong> - Twoje zapytania będą priorytetowo obsługiwane przez nasz zespół, otrzymasz odpowiedź w ciągu 24 godzin</span>
      </div>
      <div class="bonus-container grey">
        <span class="pi pi-trophy bonus-icon"></span>
        <span class="price-text">Odblokowane <strong>osiągnięcia</strong> premium</span>
      </div>
      <Button v-if="hideButtons === false" class="button" label="Wybieram" icon="pi pi-check" @click="handlePlanSelected"></Button>
    </div>
  </div>
</template>

<style scoped>
.button {
  margin-bottom: 1rem;
  margin-top: 1rem;
  width: 94%;
}
.price {
  color: #8B5CF6;
  font-weight: bold;
  margin-bottom: 0;
  margin-top: 0.5rem;
  font-size: 4rem;
}
.plan-container {
  border: 2px solid #8B5CF6;
  border-radius: 15px;
  text-align: center;
  min-height: 540px;
  overflow: hidden;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}
.plan-card.most-popular {
  background-color: #f3faf8;
  transform: scale(1.1);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  border-color: #16a34a;
}
.plan-content-header{
  height: 40px;
  border-radius: 15px 15px 0 0;
}
.header{
  padding: 0.5rem;
  margin: 0;
  color: white;
  background-color: #22c55e;
}
.discount-label {
  font-weight: bold;
  color: #22c55e;
  font-size: 1.5rem;
  margin: 0;
}
.bonus-container {
  display: flex;
  align-items: center;
  gap: 1rem;
  color: #4b5563;
  padding: 1rem;

}

.bonus-icon {
  flex-shrink: 0;
  font-size: 1.5rem;
  color: #8B5CF6;
  width: 2rem;
  text-align: left;
}
.price-text {
  text-align:left;
}
.grey {
  background-color: #ececec;
}
.old-price {
  text-decoration: line-through;
  color: #bebdbd;
  margin-right: 1rem;
  font-size: 3rem;
}
.min-price-value {
  text-decoration: line-through;
  color: #bebdbd;
  margin-right: 1rem;
  font-weight: bold;
}
.min-price {
  font-size: 0.8rem;
}
@media screen and (max-width: 640px) {
  .button {
    font-size: 1rem;
  }
  .plan-card.most-popular {
    transform: scale(1);
  }
}
</style>